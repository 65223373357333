
.WikipediaSummaryCard {
    display: flex;
    align-items: flex-start;
}

.WikipediaSummaryContent {
    padding: 10pt;
}

.WikipediaSummaryContent p {
    padding-bottom: 12pt;
    padding-top: 12pt;
}

/* Small screens */
@media all and (max-width: 500px) {
    .WikipediaSummaryCard {
      /* On small screens, we are no longer using row direction but column */
      flex-direction: column;
    }
}