.completed-card {
    align-items: center;
    color: forestgreen;
    display: flex;
    font-size: xx-large;
    height: 100%;
    justify-content: center;
    width: 100%;
}

