.flashcard-card {
    border-radius: 3.33%;
    border-style: solid;
    display: flex;
    height: 100%;
    margin: 0;
}

.flashcard-card .flashcard-card-title {
    display: flex;
    font-size: xx-large;
    justify-content: center;
}