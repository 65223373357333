.prompt {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: space-between;
}

.prompt-buttons {
    padding: 32pt;
}

.prompt-preview {
    width: 300px;
    height: 300px;
}

.prompt-title {
    padding: 32pt;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
}

.destructive .prompt-title {
    background-color: darkred;
}

.prompt h2 {
    display: none;
}
