
.note-emblem-view {
    padding: 2px;
    transform: rotate(3deg);
}

.note-emblem-view-note {
    height: 30px;
    width: 30px;
}

.note-blue {
    background: #a4e1fc;
}

.note-green {
    background: #a0f990;
}

.note-pink {
    background: #f7a1f5;
}

.note-purple {
    background: #9d9bfc;
}

.note-yellow {
    background: #ffff99
}