
.ObjectEditor {
    display: flex;
    flex-direction: column;
}

.ObjectEditor .ObjectEditor {
    padding: 8pt;
}

.ObjectEditorRow {
    margin-bottom: 8pt;
}

.ObjectEditorLabel {
    font-size: 8pt;
}

.ObjectEditorField {
    background-color: var(--ion-color-step-100, #F0F0F0);
    border-radius: 3pt;
}
