.timer {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.timer .timer-display {
    display: flex;
    font-size: xx-large;
    margin: auto;
}

.timer .timer-buttons {
    align-self: center;
}