.date-number-emblem-view {
    height: 32px;
    width: 32px;
}

.date-number-emblem-view-day {
    background-color: wheat;
    color: black;
    text-align: center;
    width: 100%;
}

.date-number-emblem-view-month {
    background-color: steelblue;
    color: white;
    font-size: xx-small;
    text-align: center;
    width: 100%;
}