
.TaskHistory {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.TaskHistoryRow {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 16pt;
}

.TaskHistoryTimespan {
    font-weight: bold;
    font-size: large;
}

.TaskHistoryTitle {
    font-weight: bold;
    font-size: x-large;
    margin-bottom: 24pt;
}