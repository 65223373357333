
.cue-card {
    align-items: center;
    background-color: #1C76B8;
    display: flex;
    height: 100%;
    justify-content: center;
}

.cue-card-title {
    color: white;
    font-size: 4em;
    font-weight: 0;
    text-align: center;
    text-transform: uppercase;
}