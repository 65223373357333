
.tree-item-header {
    display: flex;
    align-items: center;
    flex-direction: row;
    padding-bottom: 6pt;
    padding-top: 6pt;

    border-bottom-color: #444444;
    border-bottom-style: solid;
    border-bottom-width: 1px;

}

.tree-item-header-emblem {
    margin-right: 6pt;
}

.tree-item-header-title {
    cursor: pointer;
    flex-grow: 1;
    overflow-wrap: anywhere;
}

.tree-item-panel {
    margin-left: 12pt;
}

