.content {
    position: relative;
    display: flex;
    flex-direction: column;
    text-align: center;
    height: 100%;
    color: rgb(63, 63, 63);
    font-size: 1.2em; 
}

.sunrise {
    border-bottom-style: solid;
    border-bottom-width: thin;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: palegoldenrod;
}

.arrow-icon {
    font-size: 3em;
}

.sun-action-container {
    display: flex;
    flex-grow: 1;
    background-color: rgba(0, 0, 0, 0.219);
    background-color: rgb(63, 63, 63);
}

.sun-action-icon {
    width: 50%;
    height: 50%;
    margin: auto;
}

.sunset {
    border-top-style: solid;
    border-top-width: thin;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(202, 152, 94);
    background-color: darkgoldenrod;
}

.sun-action {
    font-weight: bold;
}

.time {
    font-size: 1.2em;
}
