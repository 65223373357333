.ISO4217CodeEmblem {
    align-items: center;
    display: flex;
    justify-content: center;
}

.ISO4217CodeEmblemL1 {
    font-size: 32px;
    height: 32px;
    text-align: center;
    width: 32px;
}