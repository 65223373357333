.earthquake-main {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-evenly;
}

h1 {
    font-size: x-large;
}

.earthquake-icon {
    display: block;
    filter: invert(96%) sepia(96%) saturate(0%) hue-rotate(124deg) brightness(105%) contrast(103%);
    margin-bottom: -24pt;
    margin-top: -24pt;
    width: 90px;
}

.earthquake-location {
    display: inline-flex;
    font-size: medium;
    margin: 0 5%;
    padding: 1%;
    text-align: center;
}

.earthquake-information {
    border-style: solid;
    border-width: medium;
    font-size: medium;
    max-width: 300px;
    text-align: center;
}