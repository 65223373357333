
/* Adapted from http://creative-punch.net/2014/02/create-css3-post-it-note/ */

.note-card {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.note-paper {
    box-shadow: 3px 3px 10px 2px rgba(0,0,0,0.3);
    color: #333;
    font-size: 30px;
    height: 300px;
    padding: 20px;
    width: 300px;
}

.yellow {
    background: #eae672;
    transform: rotate(3deg);
    -webkit-transform: rotate(3deg);
    -moz-transform: rotate(3deg);
    -o-transform: rotate(3deg);
    -ms-transform: rotate(3deg);
}
