.definition-card-view {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
}

.definition-card-view dt {
    display: inline;
    font-weight: bold;
}

.definition-card-view dt::after {
    content: ": ";
}

.definition-card-view dd {
    display: inline;
    margin-left: 6pt;
}