.MathProblem {
    display: flex;
    flex-direction: column;
    font-size: large;
    height: 100%;
    margin: auto;
    width: 50%;
}

.MathProblem .problem {
    display: flex;
    font-size: 15vw;
    justify-content: space-between;
    margin: auto;
    width: 100%;
}

.MathProblem .answer {
    display: flex;
    justify-content: space-around;
    border-radius: 3.33%;
    border-style: solid;
    margin: auto;
    padding: 4vw 10vw 4vw 10vw;
    text-align: center;
    width: 50%;
}

.MathProblem .answer .value {
    font-size: x-large;
}

.MathProblem .problem .equalSign {
    display: none;
}