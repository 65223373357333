
.BingWallpaperCard {
    height: 99%;
    object-fit: cover;
    width: 100%;
}

.BingWallpaperCardTitle {
    background-color: black;
    display: inline-block;
    opacity: 50%;
    padding: 20px;    
}

