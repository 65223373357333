  
.quote {
    display: flex;
    flex-direction: column;
    font-size: 38px;
    font-family: Georgia, serif;
    justify-content: space-evenly;
    width: 100%;
    height: 100%;
}

.quote-text {
    padding-left: 12pt;
    padding-right: 12pt;
    text-align: center;
}

.quote-text::before {
    content: '\201C';
    font-size: 46px;
}

.quote-text::after {
    content: '\201D';
    font-size: 46px;
}

.quote-who {
    font-size: 28px;
    margin-top: 10px;
    text-align: center;
}

/* .quote-who::before {
    content: ' - ';
} */