
.ApodCard {
    height: 100%;
    object-fit: cover;
    width: 100%;
}

.ApodCardTitle {
    position: absolute;
    padding-left: 2%;
    opacity: 75%;
}