
.realitycheck {
    background-image: url("https://upload.wikimedia.org/wikipedia/commons/4/45/Abstract_120.JPG");
    background-size: cover;
    display: flex;
    align-items: center;
    height: 100%; 
    justify-content: center;
}

.realitycheck-button {
    background-color: black;
    color: white;
    font-size: xx-large;
    justify-self: center;
    opacity: 0.6;
    padding: 12pt;
}

.realitycheck-text {
    background-color: black;
    color: white;
    justify-self: center;
    opacity: 0.6;
    font-size: xx-large;
    padding: 12pt;
}