  
.bill-card {
    align-items: center;
    background-color: darkgreen;
    display: flex;
    flex-direction: column;
    font-size: xx-large;
    justify-content: space-evenly;
    width: 100%;
    height: 100%;
}

.bill-card-account {
    color: white;
    font-size: large;
}

.bill-card-amount {
    background-color: white;
    border-radius: 10pt;
    color: black;
    display: block;
    opacity: .76;
    padding: 10pt;
    text-align: center;
}

.bill-card-title {
    color: white;
    display: block;
    text-align: center;
}