.whats-new-card-view {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.whats-new-card-view-header {
    display: flex;
    flex-direction: row;
}

.whats-new-card-view-title {
    padding-left: 8pt;
}

.whats-new-card-view-content h2 {
    font-weight: bold;
}

.whats-new-card-view-content p {
    padding-top: 12pt;
}