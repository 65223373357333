.splash {
    background-size: cover;
    background-position-x: center;
    background-position-y: center;
    height: 100%; 
}

.splash-text {
    background-color: black;
    color: white;
    display: inline-block;
    font-size: 28px;
    opacity: 0.7;
    padding: 20px;
    text-transform: lowercase;
}

.splash-title {
    font-weight: bold;
}
