
/*

Light desktop style:
- text: #222222

*/

.reddit-t3-header-logo {
    vertical-align: middle;
}

.reddit-t3-header-subreddit {
    font-size: small;
    padding-left: 4pt;
    padding-right: 4pt;
}

.reddit-t3-header-author {
    font-size: small;
}

.reddit-t3-header-title {
    font-size: medium;
}

.reddit-t3-content {
    width: 100%;
}

.reddit-t3-image {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

