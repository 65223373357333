
.weather-alert-main {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-evenly;
}

.weather-alert-icon {
    display: block;
    filter: invert(96%) sepia(96%) saturate(0%) hue-rotate(124deg) brightness(105%) contrast(103%);
    margin-bottom: -24pt;
    margin-top: -24pt;
    width: 90px;
}

.weather-alert-area {
    font-size: medium;
    max-width: 300px;
    text-align: center;
}

.weather-alert-panels {
    display: flex;
    justify-content: space-between;
}

.weather-alert-panel {
    border-color: gray;
    border-width: 1px;
    border-style: dotted;
    font-size: 10pt;
    margin: 2pt;
    padding: 12pt;
    text-align: center;
    width: 100px;
}

.weather-alert-panel-label {
    font-weight: bold;
}

.weather-alert-instructions {
    max-width: 300px;
}

.Minor {
    background-color: wheat;
    color: black;
}

.Moderate {
    background-color: darkorange;
    color: black;
}

.Severe {
    background-color: darkred;
    color: lightcoral;
}

.Extreme {
    background-color: darkred;
    color: white;;
}
