.detail {
    display: flex;
    flex-direction: column;
    padding: 2% 5%;
}

.detail ion-card-header {
    display: flex;
    padding: 1%;
}

.detail h1 {
    align-items: center;
    display: flex;
    flex-direction: row;
    margin: auto;
}

.detail .earthquake-icon {
    display: block;
    filter: invert(96%) sepia(96%) saturate(0%) hue-rotate(124deg) brightness(105%) contrast(103%);
    margin: auto;
    width: 50px;
}

.detail ion-card-content {
    display: flex;
    flex-direction: row;
}

.detail ion-list {
    width: 100%;
}

.detail ion-label {
    border-radius: 0.3%;
    font-weight: bolder;
    font-size: xx-large;
    padding: 2pt;
}

.detail ion-text {
    border-radius: 0.3%;
    padding: 3pt;
}

.detail ion-item {
    border-width: thin;
    border-style: solid;
    color: darkgrey;
    display: block;
}