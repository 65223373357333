  
.goal-card {
    background-image: url(https://images.unsplash.com/photo-1518601794912-1af91724e528?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1900&q=80);
    background-size: cover;
    display: flex;
    flex-direction: column;
    font-size: xx-large;
    justify-content: space-evenly;
    width: 100%;
    height: 100%;
}

/*
credit https://unsplash.com/@oladimeg
*/

.goal-card-title {
    background-color: white;
    color: black;
    display: block;
    opacity: .76;
    text-align: center;
    text-transform: uppercase;
}